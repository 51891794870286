import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../../components/Layout'



function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}


export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
    this.state = { copySuccess: '' }
  }

    copyToClipboard = (e) => {

        this.textArea.select();
        document.execCommand('copy');
        // This is just personal preference.
        // I prefer to not show the the whole text area selected.
        e.target.focus();
        this.setState({ copySuccess: 'animation' });

        setTimeout(
            function() {
                this.setState({ copySuccess: '' });
            }
                .bind(this),
            3000
        );
    };


  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }


  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error))

  }

  render() {
    return (

      <Layout>

        <section className="section lab-bg contact-us">
          <div className="container">
            <div className="content contact-page">
              <h1 className="title">Contactez-nous!</h1>
              <div className="is-flex">
                <div className="has-border">
                    <a className="map" href="https://goo.gl/maps/2yvDxys1gjQZb7Xc6" target="_blank">3175 Chemin des<br/>Quatre-Bourgeois bureau 150<br/>Québec, QC G1W 2K7</a>
                    <a className="tel" href="tel:418-781-1777" target="_blank">418-781-1777</a>
                </div>
                <div className="has-border">
                    <p>Faites-nous parvenir votre<br/>
                    curriculum vitae à l'adresse suivante:</p>
                    <a onClick={this.copyToClipboard}
                       value="vmarier@effenti.ca" id="clipboard" className="btn-email hidden-mobile"  target="_blank" rel="noopener noreferrer">
                        vmarier@effenti.ca
                        <div id="textslide">Valérie Marier, vice-présidente</div>
                        <div id="copied">
                            <span className={"clipboard "+this.state.copySuccess}>Adresse copiée</span>
                        </div>
                    </a>

                    <p className="only-mobile subtitle">Valérie Marier<br/>vice-présidente</p>
                    <a
                        href="mailto:vmarier@effenti.ca" id="clipboard" className="btn-email only-mobile"  target="_blank" rel="noopener noreferrer">
                        vmarier@effenti.ca
                    </a>


                    <form className="hidden-mobile">
                        <textarea
                        ref={(textarea) => this.textArea = textarea}
                        defaultValue='vmarier@effenti.ca'

                        />

                    </form>

                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="spacer-red triangle-red-bottom is-relative margin-negative"></div>
      </Layout>
    )

  }

}

